import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import instance from '../../../shared/interceptor';
import { companyState } from '../../../shared/store/slices/userSlice';
import { REMOVE_FROM_SPACE } from '../../../shared/util/constant';
import { getFullname, globalError, setLoader } from '../../../shared/util/util';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';

const RemoveMemberFromTheSpaceDialog = ({
  RemoveMemberFromSpace,
  RemoveMemberFromSpaceDialogClose,
  currentSpace,
  userDetail,
}) => {
  const dispatch = useDispatch();
  const companySel = useSelector(companyState);

  const removeMemberFromSpaceList = async () => {
    try {
      setLoader(dispatch, true);
      let url = `${REMOVE_FROM_SPACE.replace(':id', companySel.id).replace(':space_id', currentSpace.id).replace(':space_member', userDetail?.system_id)}`;
      await instance.delete(url);
      RemoveMemberFromSpaceDialogClose(null, true);
      dispatch(setSuccessMsg('User removed successfully'));
    } catch (e) {
      let error = e?.['space_member'] ? e?.['space_member']?.[0] : e;
      globalError(dispatch, error);
    } finally {
      setLoader(dispatch, false);
    }
  };

  const handleModalClose = (e, reason) => {
    if (reason && reason == 'backdropClick') return;
    RemoveMemberFromSpaceDialogClose(e);
  };

  //==============================================
  return (
    <Dialog open={RemoveMemberFromSpace} onClose={handleModalClose}>
      <DialogContent aria-label="remove-mem-from-space-dialog">
        {/* <span className="modal-close be-close" onClick={RemoveMemberFromSpaceDialogClose}></span> */}
        <div className="modal-body">
          <div className="modal-title">
            <h2>
              Remove{' '}
              {getFullname(userDetail?.first_name, userDetail?.last_name)} from{' '}
              {currentSpace?.name}
            </h2>
            <p>
              By doing so the member will not able to work on any Buildcard of
              this space.
            </p>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button
            variant="contained"
            className="sm-btn grey-btn"
            onClick={RemoveMemberFromSpaceDialogClose}
          >
            Cancel
          </Button>
          <Button
            aria-label="remove-btn"
            className="sm-btn green-btn"
            variant="contained"
            onClick={removeMemberFromSpaceList}
          >
            Confirm remove
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveMemberFromTheSpaceDialog;
