import { Navigate, Route, Routes } from 'react-router-dom';

import Activity from '../components/Activity';
import DefaultRoute from '../components/DefaultRoute';
import SpaceIdeaboard from '../pages/building/SpaceIdeaboard';
import SpaceGoals from '../pages/building/goals/SpaceGoals';
//import BusinessGoals from '../pages/company/goals/BusinessGoals';
import SpaceMeetings from '../pages/building/SpaceMeetings';
import SpaceGoalOverview from '../pages/building/goals/SpaceGoalOverview';
import PlaygroundMeeting from '../pages/building/projects/PlaygroundMeeting';
import PlaygroundWelcome from '../pages/building/projects/PlaygroundWelcome';
import PlaygroundWhiteboard from '../pages/building/projects/PlaygroundWhiteboard';
import CompanyCredits from '../pages/company/credits/CompanyCredits';
import BusinessGoalDetails from '../pages/company/goals/BusinessGoalDetails';
import BusinessGoals from '../pages/company/goals/BusinessGoals';
import Checkout from '../pages/onboard/plans/Checkout';
import ForgotPassword from '../pages/pre-login/ForgotPassword';
import Login from '../pages/pre-login/Login';
import ResetPassword from '../pages/pre-login/ResetPassword';
import Signup from '../pages/pre-login/signup';
import CanActivateChild from './CanActivateChild';
import Protected from './Protected';
import {
  AccountLogo,
  AccountName,
  BuildingSpaces,
  ChoosePlan,
  CompanyCustomRolesPermissions,
  CompanyDefaultRolesPermissions,
  CompanyIntegrations,
  CompanyIntegrationsDetail,
  CompanySettings,
  CompanySetup,
  CreditsAndPlan,
  ItAdminOverview,
  KpiDetail,
  MeetingSummary,
  MemberDashboard,
  MemberDetail,
  MemberProfile,
  MembersDashboard,
  NatashaIntro,
  NatashaRecommendedPlan,
  NatashaWelcome,
  OwnerOverview,
  PaymentSuccess,
  PlanPaymentsBillingDetails,
  PlanPaymentLogs,
  PlaygroundBuildcard,
  ProfilePhoto,
  ProfileSetup,
  ProjectSelection,
  RefineStudio,
  SpaceCredits,
  SpaceOverview,
  SpaceTeam,
  WaitingOnPayment,
  WorkspaceCustomisation,
} from './lazyRoutes';
import { SHOW_PLANS, SPACE_LIST } from './util/constant';
import DemoPurposeSignin from '../pages/demo-purpose/DemoPurposeSignin';
import { useSelector } from 'react-redux';
import { companyState } from './store/slices/userSlice';
import DuplicateBuildcard from '../pages/company/duplicate-buildcards/DuplicateBuildcard';
import Catalog from '../pages/company/catalog/Catalog';
import SpaceTopNavigation from '../pages/building/SupplyChainDistributionNavigation';
import { currentSpaceState } from './store/slices/companySlice';

function AppRouting() {
  const compSel = useSelector(companyState);
  const currentSpaceSel = useSelector(currentSpaceState);

  const accessComp = (access, mod, Comp, entity = {}) => {
    return (
      <CanActivateChild {...{ access, mod, entity }}>{Comp}</CanActivateChild>
    );
  };

  function MultiRoute(paths) {
    return paths.map((p) => (
      <Route key={p?.route} element={p?.permission} path={p?.route} />
    ));
  }

  //=====================================================

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/signin" />} />
      <Route path="/signin" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/show-plans" element={<ChoosePlan />} />
      <Route
        path="/demo-signin"
        element={
          compSel?.demo_company_users?.length > 0 ||
          JSON.parse(localStorage.getItem('company'))?.demo_company_users
            ?.length > 0 ? (
            <DemoPurposeSignin />
          ) : (
            <Navigate to="/" replace />
          )
        }
      />

      <Route path="dashboard" element={<Protected />}>
        <Route path="" element={<WaitingOnPayment />} />
      </Route>

      <Route path="/onboarding" element={<Protected />}>
        <Route
          path=""
          element={<Navigate to="/onboarding/projects" replace />}
        />
        <Route path="projects" element={<ProjectSelection />} />
        <Route path="natasha-welcome" element={<NatashaWelcome />} />
        <Route path="natasha-plan" element={<NatashaRecommendedPlan />} />
        <Route path="owner-overview" element={<OwnerOverview />} />
        <Route path="it-admin-overview" element={<ItAdminOverview />} />
        {/* <Route path="waiting-on-payment" element={<WaitingOnPayment />} /> */}{' '}
        {/* this has to be remove */}
        <Route path="member-dashboard" element={<MemberDashboard />} />
      </Route>

      <Route path="/plans" element={<Protected />}>
        <Route path="" element={<Navigate to={SHOW_PLANS} replace />} />
        <Route path="checkout" element={<Checkout />} />
        {/* <Route path="checkout" element={<CheckoutPlanSelection />} /> */}
        {/* <Route path="billing-details" element={<PlanBillingDetails />} /> */}
        {/* <Route path="payment" element={<PlanPayment />} /> */}
      </Route>

      <Route path="/payment-success" element={<PaymentSuccess />} />
      <Route path="/member-onboarding" element={<Protected />}>
        <Route
          path=""
          element={<Navigate to="/member-onboarding/natasha-intro" replace />}
        />
        <Route path="natasha-intro" element={<NatashaIntro />} />
        <Route path="profile-setup" element={<ProfileSetup />} />
        <Route path="profile-photo" element={<ProfilePhoto />} />
      </Route>

      <Route
        path="/workspace-customisation"
        element={<WorkspaceCustomisation />}
      >
        <Route path="" element={<CompanySetup />} />
        <Route path="account-name" element={<AccountName />} />
        <Route path="account-logo" element={<AccountLogo />} />
      </Route>

      <Route path="/plan-payments" element={<Protected />}>
        <Route
          path=""
          element={<Navigate to="/plan-payments/credits" replace />}
        />
        <Route
          path="credits"
          element={accessComp('read', 'payment', <CreditsAndPlan />)}
        />
        <Route
          path="billing-details"
          element={<PlanPaymentsBillingDetails />}
        />
      </Route>

      <Route path="/company" element={<Protected />}>
        <Route
          path=""
          element={<Navigate to="/company/company-settings" replace />}
        />
        <Route
          path="company-settings"
          element={accessComp('update', 'company', <CompanySettings />)}
        />

        <Route path="roles-permissions">
          <Route
            path=""
            element={
              <Navigate to="/company/roles-permissions/default-roles" replace />
            }
          />
          <Route
            path="default-roles"
            element={accessComp(
              'update',
              'role',
              <CompanyDefaultRolesPermissions />
            )}
          />
          <Route
            path="custom-roles"
            element={accessComp(
              'update',
              'role',
              <CompanyCustomRolesPermissions />
            )}
          />
        </Route>

        <Route path="members">
          <Route
            path=""
            element={accessComp('update', 'user', <MembersDashboard />)}
          />
          <Route
            path=":id/member-detail"
            element={accessComp('update', 'user', <MemberDetail />)}
          />
        </Route>

        <Route path="spaces">
          <Route path="" element={<Navigate to={SPACE_LIST} replace />} />
          <Route
            path="all-spaces"
            element={accessComp('update', 'space', <BuildingSpaces />)}
          />
          {MultiRoute([
            { route: ':id/overview', permission: <SpaceTopNavigation /> },
            {
              route: ':id/team',
              permission: accessComp(
                'read',
                'space_member',
                <SpaceTopNavigation />,
                {
                  id: currentSpaceSel?.system_id,
                  type: 'Space',
                }
              ),
            },
            {
              route: ':id/credits',
              permission: accessComp(
                'read',
                'space_credit',
                <SpaceTopNavigation />,
                {
                  id: currentSpaceSel?.system_id,
                  type: 'Space',
                }
              ),
            },
            {
              route: ':id/activity',
              permission: <SpaceTopNavigation />,
            },
            {
              route: ':id/space-goals',
              permission: <SpaceTopNavigation />,
            },
            {
              route: ':id/meetings',
              permission: <SpaceTopNavigation />,
            },
          ])}
          {/* <Route path=":id/overview" element={<SpaceTopNavigation />} /> */}
          {/* <Route
            path=":id/team"
            element={accessComp('read', 'space_member', <SpaceTeam />, {
              type: 'Space',
            })}
          /> */}
          {/* <Route
            path=":id/credits"
            element={accessComp('read', 'space_credit', <SpaceCredits />, {
              type: 'Space',
            })}
          /> */}
          {/* <Route path=":id/activity" element={<Activity />} /> */}
          <Route path=":id/space-playground" element={<PlaygroundWelcome />} />
          <Route path=":id/ideaboard" element={<SpaceIdeaboard />} />
          <Route
            path=":id/meeting-playground/:meeting_id"
            element={<PlaygroundMeeting />}
          />
          <Route
            path=":id/whiteboard-playground/:whiteboard_id"
            element={<PlaygroundWhiteboard />}
          />
          <Route
            path=":id/meeting-summary/:meeting_id"
            element={<MeetingSummary />}
          />
          {/* <Route path=":id/space-goals" element={<SpaceGoals />} /> */}
          <Route
            path=":id/space-goals/:space_goal_id"
            element={<SpaceGoalOverview />}
          />
          <Route
            path=":id/playground/:bc_id"
            element={<PlaygroundBuildcard />}
          />
          <Route
            path=":id/playground/:bc_id/duplicate-buildcard"
            element={<DuplicateBuildcard />}
          />
          <Route
            path=":id/playground-welcome"
            element={<PlaygroundWelcome />}
          />
          <Route path=":id/refine-studio" element={<RefineStudio />} />
          {/* <Route path=":id/meetings" element={<SpaceMeetings />} /> */}
        </Route>

        <Route path="integrations">
          <Route
            path=""
            element={accessComp('read', 'integration', <CompanyIntegrations />)}
          />
          <Route
            path=":id"
            element={accessComp(
              'read',
              'integration',
              <CompanyIntegrationsDetail />
            )}
          />
        </Route>

        <Route path="business-goals">
          <Route
            path=""
            element={accessComp('update', 'company_goal', <BusinessGoals />)}
          />
          <Route
            path=":goal_id"
            element={accessComp(
              'update',
              'company_goal',
              <BusinessGoalDetails />
            )}
          />
          <Route
            path=":goal_id/kpi/:kpi_id"
            element={accessComp('update', 'company_goal', <KpiDetail />)}
          />
        </Route>
        <Route path="company-credits" element={<CompanyCredits />} />
        <Route path="catalogue" element={<Catalog />} />
      </Route>

      <Route path="/settings" element={<Protected />}>
        <Route
          path=""
          element={<Navigate to="/settings/member-profile" replace />}
        />
        <Route path="member-profile" element={<MemberProfile />} />
      </Route>

      {/* Default route if no route matched */}
      <Route path="*" element={<DefaultRoute />} />
    </Routes>
  );
}

export default AppRouting;
