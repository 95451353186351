import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { startTransition, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import images from '../../../assets/images';
import ExitConfirmDialog from '../../../components/dialog/playground-dialog/ExitConfirmDialog';
import UnauthorizedAccess from '../../../components/UnauthorizedAccess';
import { usePrompt } from '../../../shared/hooks/usePrompt';
import instance from '../../../shared/interceptor';
import {
  createExternalAppSession,
  getCurrentSpaceDetail,
  sendSlackNotification,
} from '../../../shared/services/common.service';
import { currentSpaceState } from '../../../shared/store/slices/companySlice';
import {
  bmeetUrl,
  bmeetingUrlState,
  companyState,
  leavingUrlState,
  userState,
} from '../../../shared/store/slices/userSlice';
import { SPACES } from '../../../shared/util/constant';
import {
  getFullname,
  globalError,
  setLoader,
  transNavigation,
} from '../../../shared/util/util';
import '../project.scss';

export default function PlaygroundMeeting() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const compSel = useSelector(companyState);
  const currentSpaceSel = useSelector(currentSpaceState);
  const meetingSel = useSelector(bmeetingUrlState);
  const userSel = useSelector(userState);
  const leavingUrlSel = useSelector(leavingUrlState);

  const [iframeUrl, setiframeUrl] = useState(true);
  const [iframeLoading, setIframeLoading] = useState(false);
  const [leavingUrlSt, setLeavingUrlSt] = useState('');
  const [showPrompt, setShowPrompt] = useState(false);
  const [meetingData, setMeetingData] = useState(null);
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    getCurrentSpaceDetail(dispatch, compSel?.id, params?.id);
    if (!meetingSel?.id) {
      getMeetingData();
      // state ? navigate(`/company/spaces/${params?.id}/ideaboard`) : currentProjectSel?.id ? navigate(`/company/spaces/${params?.id}/project/${currentProjectSel?.id}/playground-welcome`) : navigate(-1);
    }
    if (meetingSel?.url) {
      setStartTime(performance.now());
      setIframeLoading(true);
      createExternalAppSession(params, compSel, 'bmeet', dispatch);
    }
  }, [meetingSel?.id, params?.id, params?.meeting_id]);

  useEffect(() => {
    const handler = (
      ev: MessageEvent<{ type: string; schedule_meeting_id: string }>
    ) => {
      if (typeof ev?.data !== 'object') return;
      if (!ev?.data?.type) return;
      if (ev?.data?.type !== 'meeting-closed') return;
      if (!ev?.data?.schedule_meeting_id) return;
      if (ev?.data?.schedule_meeting_id) {
        closeAndNavigate();
      }
    };

    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
      setiframeUrl(null);
      window.onbeforeunload = null;
    };
  }, []);

  const closeAndNavigate = () => {
    setiframeUrl(null);
    window.onbeforeunload = null;
    console.log(state, 'state');
    if (state) {
      navigate(`/company/spaces/${params?.id}/ideaboard`, { replace: true });
    } else {
      navigate(`/company/spaces/${params?.id}/meetings`, { replace: true });
    }
  };

  const [showDialog, setShowDialog] = usePrompt(
    'Are you sure, you wnat to exit?',
    !!iframeUrl,
    showPrompt
  );

  useEffect(() => {
    setLeavingUrlSt(leavingUrlSel);
  }, [leavingUrlSel]);

  const discardAndNavigate = () => {
    setShowDialog(false);
    setShowPrompt(false);
    setiframeUrl(null);
    navigate(leavingUrlSt, { replace: true });
    window.onbeforeunload = null;
  };

  const getMeetingData = async () => {
    try {
      setLoader(dispatch, true);
      const url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/meetings/${params?.meeting_id}`;
      let meetingRes = await instance.get(url);
      setMeetingData(meetingRes['meeting']);
      dispatch(bmeetUrl(meetingRes['meeting']));
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  };

  const handleIframLoad = () => {
    setIframeLoading(false);
    let endTime = performance.now();
    let timeTaken = endTime - startTime;
    if (
      process.env.NODE_ENV !== 'development' &&
      startTime &&
      timeTaken > 5000
    ) {
      sendSlackNotification(
        'Bmeet',
        timeTaken,
        'meeting',
        `${params?.id}/meeting-playground/${params?.meeting_id}`,
        dispatch
      );
    }
  };

  //===========================================================
  return (
    <div className="meeting-playground-page" aria-label="meeting-playground">
      {/* <div className="page-container top-space">
        <div className="main-page-header">
          <div className="page-breacrumb">
            <div className="breadcrumb-wrapper">
              <ul className="breadcrumb-links">
                <li><Link to={ON_BOARDING_WAITING_PAYMENT}>Building</Link></li>
                <li>{isAllowedTo('update', 'space', permissions) ? <Link to={SPACE_LIST}>Spaces</Link> : 'Spaces'}</li>
                <li><Link to={`/company/spaces/${currentSpaceSel?.id}/overview`}>{ currentSpaceSel?.name }</Link></li>
                <li><Link to={`/company/spaces/${currentSpaceSel?.id}/ideaboard`}>Ideaboard</Link></li>
              </ul>
            </div>
          </div>
          <div className="page-title">
            <h2 aria-label="member-heading">{meetingSel?.title}</h2>
          </div>
        </div>
      </div> */}
      <div className="page-title">
        <h6 aria-label="member-heading">
          <em className="icon be-calendar"></em>
          {meetingSel?.title || meetingData?.name}
        </h6>
      </div>

      {iframeUrl && meetingSel?.url && (
        <div className="iframe-wrapper" aria-label="iframe-meeting-label">
          <iframe
            allow="camera *; microphone *; display-capture *; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            onLoad={handleIframLoad}
            aria-label="iframe-label"
            title="bmeet application"
            src={
              meetingSel?.url +
              '&source=enterprise&is_source_enterprise_surface=true' +
              `&email=${userSel?.email}&username=${getFullname(userSel?.first_name, userSel?.last_name)}`
            }
            className="studio-iframe"
            style={{
              width: '100%',
              height: '100%',
              position: 'relative',
              top: '0',
              right: '0',
              left: '0px',
              backgroundColor: '#ffffff',
            }}
          />
        </div>
      )}
      {false && (
        <div className="footer-section">
          <div className="assigned-buildcard">
            <div className="table-wrapper table-responsive assigned-buildcard-table">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Active Buildcard</TableCell>
                      <TableCell className="text-right">Featuresets</TableCell>
                      <TableCell className="text-right">Credits</TableCell>
                      <TableCell className="text-right">Duration</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <span className="link-wrap">
                          <span className="title">{currentSpaceSel?.name}</span>{' '}
                          <span className="primary-link">Change</span>
                        </span>
                      </TableCell>
                      {/* <TableCell><span>{ currentProjectSel?.name }</span> <Link to="/" className="primary-link" target="_blank" >Change</Link></TableCell> */}
                      <TableCell className="text-right">0</TableCell>
                      <TableCell className="text-right">13</TableCell>
                      <TableCell className="text-right">0 days</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      )}
      {!!showDialog && !!iframeUrl && (
        <ExitConfirmDialog
          {...{
            condition: !!showDialog && !!iframeUrl,
            discardAndNavigate,
            setShowDialog,
            title: 'Exit meeting',
            desc: 'Are you sure, you want to exit?',
          }}
        />
      )}
    </div>
  );
}
