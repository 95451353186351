import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import * as React from 'react';

import CreditHistory from './CreditHistory';
import CreditOverview from './CreditOverview';
import ManageCredit from './ManageCredit';
import './credits.scss';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UnauthorizedAccess from '../../../components/UnauthorizedAccess';
import consumer from '../../../shared/cable';
import instance from '../../../shared/interceptor';
import RightPageHeader from '../../../shared/shared-comp/RightPageHeader';
import {
  companyState,
  permissionState,
} from '../../../shared/store/slices/userSlice';
import { COMPANY } from '../../../shared/util/constant';
import {
  globalError,
  isAllowedTo,
  transNavigation,
} from '../../../shared/util/util';

function CompanyCredits() {
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const permissions = useSelector(permissionState);
  const navigate = useNavigate();

  const [value, setValue] = React.useState('1');
  const [spaceCredits, setSpaceCredits] = useState(null);
  const [plan, setPlan] = useState(null);
  const [graphData, setGraphData] = useState([]);
  const [searchSpaceCredits, setSearchSpaceCredits] = useState(null);
  const [emptyStateOverview, setEmptyStateOverview] = useState(false);
  const [creditPlanLoading, setCreditPlanLoading] = useState(true);
  const [spaceCreditLoading, setSpaceCreditLoading] = useState(true);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development' && plan?.id) {
      consumer.subscriptions.create(
        {
          channel: `PlanCreditSubscriptionChannel`,
          channel_id: `${plan?.id}`,
        },
        {
          connected: () => console.log('connected for company credits'),
          disconnected: () => console.log('disconnected for company credits'),
          received: (data) => {
            if (data) {
              setPlan((prev) => ({ ...prev, ...data.body.data }));
              setSpaceCredits((prev) => [
                ...mutateSpaceCredits(
                  prev,
                  data.body.data.allocated_space_credits_data
                ),
              ]);
              setSearchSpaceCredits((prev) => [
                ...mutateSpaceCredits(
                  prev,
                  data.body.data.allocated_space_credits_data
                ),
              ]);
              let allocationGraphData = generateGraphData(data.body.data);
              setGraphData(allocationGraphData);
            }
          },
        }
      );

      return () => {
        consumer.disconnect();
      };
    }
  }, [plan?.id]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const getCreditPlanSub = async () => {
    try {
      setCreditPlanLoading(true);
      const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}`;
      const res = await instance.get(url);
      setPlan(res?.['plan_credit_subscription']);
      if (res && !res?.['plan_credit_subscription']?.['allocated_credits']) {
        setEmptyStateOverview(true);
      }
      let allocationGraphData = generateGraphData(
        res?.['plan_credit_subscription']
      );
      setGraphData(allocationGraphData);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setCreditPlanLoading(false);
    }
  };

  const getSpaceCredit = async () => {
    try {
      setSpaceCreditLoading(true);
      const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/space_credits`;
      const res = await instance.get(url);
      setSpaceCredits(
        res?.['space_credits'].filter((item) => item?.space?.archive == false)
      );
      setSearchSpaceCredits(res?.['space_credits']);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setSpaceCreditLoading(false);
    }
  };

  const generateGraphData = (obj) => {
    return [
      {
        name: `${obj?.credit_balance} Credit balance`,
        color: '#F3EBFF',
        borderColor: '#F3EBFF',
        borderWidth: 1,
        data: [obj?.credit_balance],
      },
      {
        name: `${obj?.allocated_credits} Credits allocated`,
        data: [obj?.allocated_credits],
        color: '#D9C4FF',
        borderColor: '#D9C4FF',
        borderWidth: 1,
      },
      {
        name: `${obj?.consumed_credits} Credits used`,
        data: [obj?.consumed_credits],
        color: '#B388FF',
        borderColor: '#B388FF',
        borderWidth: 1,
      },
    ];
  };

  const mutateSpaceCredits = (arr, receivedArr) => {
    return arr.map((item) => {
      let foundItem = receivedArr.find(
        (socItem) => socItem?.space_id == item?.space_id
      );
      if (foundItem) {
        return { ...item, ...foundItem };
      } else {
        return { ...item, allocated_credits: 0 };
      }
    });
  };

  //=========================================
  return (
    <>
      {compSel?.active_plan_credit_subscription?.id &&
      isAllowedTo('read', 'plan_credit_subscription', permissions) ? (
        <div
          className="credits-page credits-breacrumb"
          aria-label="company-credit"
        >
          <div className="page-container top-space">
            <div className="main-page-header">
              <div className="page-breacrumb">
                <div className="breadcrumb-wrapper">
                  <ul className="breadcrumb-links">
                    <li>
                      <a
                        onClick={() =>
                          transNavigation(
                            React.startTransition,
                            navigate,
                            '/company/company-settings'
                          )
                        }
                      >
                        Company
                      </a>
                    </li>
                    <li>Credits</li>
                  </ul>
                </div>
                <RightPageHeader />
              </div>
            </div>
            <div className="page-filters-wrapper secondary-header">
              <div className="left-filter">
                <div className="page-title">
                  <h2 aria-label="member-heading">Credits </h2>
                </div>
              </div>
              {/* TODO : Don't remove this, to be enabled later
              <div className="credits-right-filter">                
                <Button className="purple-btn">Top up credits</Button>
              </div> */}
            </div>

            <Box>
              <TabContext value={value}>
                <Box
                  className="page-top-navigation"
                  sx={{ borderBottom: 1, borderColor: 'divider' }}
                >
                  <TabList onChange={handleChange} aria-label="tab-list">
                    <Tab label="Overview" value="1" />
                    <Tab label="Manage Credits" value="2" />
                    <Tab label="History" value="3" />
                  </TabList>
                </Box>
                <TabPanel className="credits-content-block" value="1">
                  <CreditOverview
                    {...{
                      spaceCredits,
                      emptyStateOverview,
                      plan,
                      graphData,
                      searchSpaceCredits,
                      getCreditPlanSub,
                      getSpaceCredit,
                      setValue,
                      creditPlanLoading,
                      spaceCreditLoading,
                    }}
                  />
                </TabPanel>
                <TabPanel className="credits-content-block" value="2">
                  <ManageCredit
                    {...{
                      spaceCredits,
                      plan,
                      graphData,
                      getCreditPlanSub,
                      getSpaceCredit,
                      spaceCreditLoading,
                    }}
                  />
                </TabPanel>
                <TabPanel
                  className="credits-content-block credits-history"
                  value="3"
                >
                  <CreditHistory {...{ setValue }} />
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
      ) : (
        <UnauthorizedAccess />
      )}
    </>
  );
}

export default CompanyCredits;
