import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { startTransition, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import images from '../../../assets/images';
import UnauthorizedAccess from '../../../components/UnauthorizedAccess';
import ArchiveGoalDialog from '../../../components/dialog/business-goals-dialogue/ArchiveGoalDialog';
import AddBuildcardDialog from '../../../components/dialog/space-goal/AddBuildcardDialog';
import CreateSpaceGoalDialog from '../../../components/dialog/space-goal/CreateSpaceGoalDialog';
import instance from '../../../shared/interceptor';
import {
  addTag,
  getAllSpaceGoals,
  getAllTags,
  getCurrentSpaceDetail,
  handleDelete,
} from '../../../shared/services/common.service';
import BuildcardComp from '../../../shared/shared-comp/BuildcardComp';
import GoalDetailCard from '../../../shared/shared-comp/GoalDetailCard';
import LoaderComp from '../../../shared/shared-comp/LoaderComp';
import RightPageHeader from '../../../shared/shared-comp/RightPageHeader';
import { currentSpaceState } from '../../../shared/store/slices/companySlice';
import {
  companyState,
  permissionState,
  userPermissionState,
} from '../../../shared/store/slices/userSlice';
import { SPACES } from '../../../shared/util/constant';
import {
  debounce,
  globalError,
  isAllowedTo,
  makeDoubleDigit,
  pluralize,
  removeByAttr,
  transNavigation,
} from '../../../shared/util/util';
import './space-goals.scss';

function SpaceGoalOverview() {
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const params = useParams();
  const currentSpaceSel = useSelector(currentSpaceState);
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [spaceGoal, setSpaceGoal] = useState(null);
  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const [openEditDialogue, setOpenEditDialog] = useState(false);
  const [buildcardList, setBuildCardList] = useState([]);
  const [showSearchTags, setShowSearchTags] = useState(null);
  const [tagList, setTagList] = useState(null);
  const [spaceGoals, setSpaceGoals] = useState([]);
  const [searchSpaceGoals, setSearchSpaceGoals] = useState([]);
  const [overviewLoading, setOverviewLoading] = useState(true);

  const entity = { id: currentSpaceSel?.system_id, type: 'Space' };

  const handleDotClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [openDialogue, setOpen] = useState(null);
  const handleClickOpen = () => {
    setOpen(spaceGoal);
  };

  const handleClose = (e, render = null) => {
    setAnchorEl(null);
    setOpen(null);
    setOpenArchiveModal(false);
    setOpenEditDialog(false);
    if (render) {
      getSpaceGoalOverview();
    }
  };

  useEffect(() => {
    if (!currentSpaceSel?.id || currentSpaceSel?.id !== params?.id) {
      getCurrentSpaceDetail(dispatch, compSel?.id, params?.id);
    }
    getSpaceGoalOverview();
  }, []);

  const searchSpaceGoalList = (event) => {
    let val = event?.target?.value;
    if (val) {
      setSpaceGoals(
        searchSpaceGoals.filter((item) =>
          item?.name?.toLowerCase()?.includes(val?.toLowerCase())
        )
      );
    } else {
      setSpaceGoals(searchSpaceGoals);
    }
  };

  const getSpaceGoalOverview = async () => {
    try {
      setOverviewLoading(true);
      const url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/space_goals/${params?.space_goal_id}`;
      let res = await instance.get(url);
      setSpaceGoal(res['space_goal']);
      if (res['space_goal']['build_cards_count']) {
        getBuildcards();
        if (
          isAllowedTo(
            'read',
            'space_goal',
            permissions,
            entity,
            userPermissions
          )
        ) {
          let res = await getAllSpaceGoals(dispatch, compSel, params);
          setSpaceGoals(res?.['space_goals']);
          setSearchSpaceGoals(res?.['space_goals']);
        }
      }
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setOverviewLoading(false);
    }
  };

  const getBuildcards = async () => {
    try {
      setOverviewLoading(true);
      const url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/space_goals/${params?.space_goal_id}/tagged_buildcards`;
      let res = await instance.get(url);
      setBuildCardList(res['build_cards']);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setOverviewLoading(false);
    }
  };

  const deleteTag = async (tagId) => {
    let response = await handleDelete(dispatch, compSel, tagId);
    let filteredList = mutateList(response, false);
    setBuildCardList(filteredList);
  };

  const addTags = async (tagName, card) => {
    let response = await addTag(compSel, dispatch, tagName, card);
    let filteredList = mutateList(response, true);
    setBuildCardList(filteredList);
    handleClose(null);
  };

  const mutateList = (response, toAdd) => {
    return buildcardList?.map((item) => {
      if (item?.enterprise_id == response?.['entity_tag']?.taggable_id) {
        if (toAdd) {
          return {
            ...item,
            entity_tags: [
              ...item?.entity_tags,
              {
                id: response?.['entity_tag']?.['id'],
                name: response?.['entity_tag']?.['name'],
              },
            ],
          };
        } else {
          return {
            ...item,
            entity_tags: item?.entity_tags?.filter(
              (item) => item?.id !== response?.['entity_tag']?.id
            ),
          };
        }
      } else {
        return item;
      }
    });
  };

  const tagChangeHandler = debounce((e, card) => {
    if (e.key === 'Enter') {
      addTags(e.target.value, card);
      handleClose(e);
    } else {
      getTagList(e.target['value']);
    }
  });

  const getTagList = async (query?) => {
    let tagsRes = await getAllTags(compSel, dispatch, query);
    setTagList(tagsRes['tags']);
  };

  const updateGoals = (goal, card, remove = false) => {
    let bcToRemove = null;
    let mappedList = buildcardList?.map((item) => {
      if (item?.enterprise_id == card?.enterprise_id) {
        if (remove) {
          let removedGoal = removeByAttr(
            [...(item?.space_goals ?? [])],
            'id',
            goal?.id
          );
          if (!removedGoal?.length) {
            bcToRemove = item?.enterprise_id;
          }
          return { ...item, space_goals: removedGoal };
        }
        return {
          ...item,
          space_goals: [
            ...(item?.space_goals ?? []),
            { id: goal?.id, name: goal?.['name'] },
          ],
        };
      } else {
        return item;
      }
    });
    if (bcToRemove) {
      mappedList = removeByAttr([...mappedList], 'enterprise_id', bcToRemove);
    }
    setBuildCardList(mappedList);
  };

  //=================================================
  return (
    <>
      {isAllowedTo(
        'read',
        'space_goal',
        permissions,
        entity,
        userPermissions
      ) ? (
        <div className="goals-detail-page" data-testid="space-goals-page">
          <div className="page-container top-space">
            <div className="main-page-header">
              <div className="page-breacrumb">
                <div className="breadcrumb-wrapper">
                  <ul className="breadcrumb-links">
                    <li>
                      <a
                        onClick={() =>
                          transNavigation(
                            startTransition,
                            navigate,
                            '/company/company-settings'
                          )
                        }
                      >
                        Company
                      </a>
                    </li>
                    <li>
                      <Link to="/company/spaces/all-spaces">Spaces</Link>
                    </li>
                    <li>
                      <Link
                        to={`/company/spaces/${currentSpaceSel?.id}/overview`}
                      >
                        {currentSpaceSel?.name}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/company/spaces/${currentSpaceSel?.id}/space-goals`}
                      >
                        Goals
                      </Link>
                    </li>
                    <li>{spaceGoal?.name}</li>
                  </ul>
                </div>
                <RightPageHeader />
              </div>
              <div className="page-title">
                <div className="title-wrap">
                  <h2>{spaceGoal?.name}</h2>
                  {/* <p>{spaceGoal?.description}</p> */}
                </div>
                <div className="right-filter">
                  <div className="button-wrap">
                    {/* <Button variant="contained" className="sm-btn purple-btn" onClick={() => setOpenEditDialog(true)}>Edit</Button> */}
                    <Button
                      data-testid="more-actions-btn"
                      variant="contained"
                      className="sm-btn is-icon-btn square-btn-three-dots"
                      onClick={(e) => handleDotClick(e)}
                    >
                      <em
                        aria-label="three-dot-btn"
                        className="icon be-vertical-dots"
                      ></em>
                    </Button>
                    <Menu
                      id="basic-goal-menu"
                      anchorEl={anchorEl}
                      open={open}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      className={'three-dot-menu-paper'}
                      onClose={() => setAnchorEl(null)}
                    >
                      {/* <MenuItem data-testid="edit-dialog" className='toggle-menu-item-row'><em className='icon be-edit'></em> Edit</MenuItem> */}
                      <MenuItem
                        onClick={() => setOpenEditDialog(true)}
                        data-testid="archive-dialog"
                        className="toggle-menu-item-row"
                        aria-label="archive-btn"
                      >
                        <em className="icon be-edit"></em> Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => setOpenArchiveModal(true)}
                        data-testid="archive-dialog"
                        className="toggle-menu-item-row"
                        aria-label="archive-btn"
                      >
                        <em className="icon be-archive"></em>{' '}
                        {spaceGoal?.archive ? 'Unarchive' : 'Archive'}
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>

            <div className="inner-section-wrap space-goals">
              <div className="tab-context-wrapper">
                <GoalDetailCard
                  {...{
                    goalDetail: spaceGoal,
                    getGoalDetail: getSpaceGoalOverview,
                    update: 'space_goal',
                    setOpenEditDialogue: setOpenEditDialog,
                    title: {
                      heading: spaceGoal?.name,
                      description: spaceGoal?.description,
                    },
                  }}
                />

                <div className="whats-being-built">
                  <div className="title-wrap">
                    <div className="left">
                      <h3>What's being built</h3>
                      {!overviewLoading && (
                        <span className="count">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: pluralize(
                                buildcardList?.length > 0
                                  ? makeDoubleDigit(buildcardList?.length)
                                  : 0,
                                'Buildcard',
                                's',
                                true
                              ),
                            }}
                          ></span>
                        </span>
                      )}
                    </div>
                    <div className="right">
                      <Button
                        variant="contained"
                        data-testid="add-bc-btn"
                        className="xs-btn grey-btn btn-add-goals"
                        onClick={handleClickOpen}
                      >
                        Add Buildcard
                      </Button>
                    </div>
                  </div>

                  {/* No data found - empty state */}
                  {!spaceGoal?.build_cards_count && !overviewLoading && (
                    <div
                      className="no-data-wrapper no-ideas-yet"
                      aria-label="empty-state"
                    >
                      <em className="thumb">
                        <img
                          src={images.emptyStateIllustration}
                          alt="Spaces Flag"
                        />
                      </em>
                      <h4 aria-label="no-space">No Buildcard yet</h4>
                      <p>Add goals to keep your development on track.</p>
                      {isAllowedTo(
                        'update',
                        'buildcard',
                        permissions,
                        entity,
                        userPermissions
                      ) && (
                        <Button
                          variant="contained"
                          className="sm-btn btn-add-goals grey-btn"
                          onClick={handleClickOpen}
                        >
                          Add Buildcard
                        </Button>
                      )}
                    </div>
                  )}

                  {/* Cards wrapper */}
                  <div className="grid-view-cards-wrapper">
                    <div className="data-container">
                      <div className="row">
                        {overviewLoading && <LoaderComp maxHeight="40rem" />}

                        {spaceGoal?.build_cards_count > 0 &&
                          !overviewLoading &&
                          buildcardList.map((item, idx) => (
                            <div
                              className="col-xs-12 col-sm-6 col-md-6 col-xl-3"
                              key={item?.id}
                            >
                              <BuildcardComp
                                {...{
                                  card: item,
                                  entity,
                                  idx,
                                  spaceGoals,
                                  searchSpaceGoalList,
                                  updateGoals,
                                  setShowSearchTags,
                                  deleteTag,
                                  tagList,
                                  addTags,
                                  tagChangeHandler,
                                  spaceUid: params?.id,
                                }}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  {/* Cards wrapper end */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <UnauthorizedAccess />
      )}

      {/* Add Buildcard dialogue end */}
      {openDialogue && (
        <AddBuildcardDialog
          {...{
            openDialogue,
            handleClose,
            spaceGoalBuildcardList: buildcardList,
          }}
        />
      )}
      {/* Add Buildcard dialogue end */}

      {openArchiveModal && (
        <ArchiveGoalDialog
          {...{ archive: spaceGoal, handleClose, goal: 'space' }}
        />
      )}

      {openEditDialogue && (
        <CreateSpaceGoalDialog
          {...{
            openDialogue: openEditDialogue,
            handleClose,
            selectedGoal: spaceGoal,
          }}
        />
      )}
    </>
  );
}

export default SpaceGoalOverview;
