import { TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import images from '../../../assets/images';

import { Dialog, DialogContent } from '@mui/material';
import LoaderComp from '../../../shared/shared-comp/LoaderComp';
import { TabContext, TabList } from '@mui/lab';
import { formatDate } from '../../../shared/util/util';
import { useState } from 'react';

export const CatalogShow = ({
  openCatalog,
  handleCloseCatalog,
  catalogDetails,
  catalogDetailsLoading,
}) => {
  const [value1, setValue1] = useState('1');

  const handleCatalogTabChange = (event, newValue) => {
    setValue1(newValue);
  };

  return (
    <Dialog
      className="catalog-detail-dialog"
      open={openCatalog}
      onClose={handleCloseCatalog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="catalog-show"
    >
      {catalogDetailsLoading ? (
        <LoaderComp style={{ height: '70vh' }} />
      ) : (
        <>
          <div
            className="close-menu be-close"
            onClick={handleCloseCatalog}
          ></div>
          {catalogDetails && (
            <DialogContent className="catalog-modal-content">
              <div className="catalog-content-left">
                <div className="titleRow">
                  <div className="thumbIcon">
                    <img src={catalogDetails?.thumbnail} alt="catalog icon" />
                  </div>
                  <h2>
                    <span>Template</span>
                    {catalogDetails?.name}
                  </h2>
                </div>
                <div className="cost-feature-row">
                  <p>{catalogDetails?.description}</p>
                  <div className="catalog-modal-footer">
                    <div className="actionbx">
                      <span>Journeys</span>
                      <h5>{catalogDetails?.journey_count}</h5>
                    </div>
                    <div className="actionbx">
                      <span>FEATURES</span>
                      <h5>{catalogDetails?.feature_count}</h5>
                    </div>
                    <div className="actionbx">
                      <span>Credits</span>
                      <h5>{catalogDetails?.price?.toFixed(2)}</h5>
                    </div>
                    <div className="actionbx">
                      <span>Created on</span>
                      <h5>
                        {formatDate(
                          catalogDetails?.created_at,
                          false,
                          false,
                          'MMM DD, YYYY'
                        )}
                      </h5>
                    </div>
                    <div className="actionbx">
                      <span>Publisehd by</span>
                      <h5>{catalogDetails?.created_by}</h5>
                    </div>
                  </div>
                </div>

                <div className="projects-top-nav-wrapper">
                  <Box
                    className="tab-contest"
                    sx={{ width: '100%', typography: 'body1' }}
                  >
                    <TabContext value={value1}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                          onChange={handleCatalogTabChange}
                          aria-label="lab API tabs example"
                        >
                          <Tab label="Journey & features " value="1" />
                          <Tab label="Marketplace services" value="2" />
                        </TabList>
                      </Box>
                      <TabPanel className="catalog-wrapper" value="1">
                        <div className="catalog-tab-content">
                          <div className="catalog-table">
                            <div className="catalog-table-head">
                              <div className="table-column">
                                JOURNEY/FEATURE{' '}
                              </div>
                              <div className="table-column">Credits </div>
                            </div>
                            <div className="table-bd">
                              {catalogDetails?.journeys?.map((journey) => (
                                <>
                                  <div
                                    className="table-row sel"
                                    key={journey?.id}
                                  >
                                    <div className="table-column-left">
                                      <h3>{journey?.name}</h3>
                                    </div>
                                    <div className="table-column-right">
                                      <h3>{journey?.price?.toFixed(2)}</h3>
                                    </div>
                                  </div>

                                  {journey?.features?.map((feature) => (
                                    <>
                                      <div
                                        className="table-row"
                                        key={feature?.id}
                                      >
                                        <div className="table-column-left">
                                          <p>{feature?.title}</p>
                                        </div>
                                        <div className="table-column-right">
                                          <p>{feature?.price?.toFixed(2)}</p>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel className="catalog-wrapper" value="2">
                        <div className="catalog-tab-content">
                          <div className="catalog-table marketplace-table">
                            <div className="catalog-table-head">
                              <div className="table-column">Service </div>
                              <div className="table-column">Type </div>
                              <div className="table-column">Credits </div>
                            </div>
                            <div className="table-bd">
                              {catalogDetails?.marketplace_services?.map(
                                (service) => (
                                  <div className="table-row" key={service?.id}>
                                    <div className="table-column-left">
                                      <p className="d-flex align-items-center">
                                        {service?.icon ? (
                                          <div className="service-icon">
                                            <img src={service?.icon} />
                                          </div>
                                        ) : (
                                          <em className="img"></em>
                                        )}
                                        {service?.title}
                                      </p>
                                    </div>
                                    <div className="table-column-right">
                                      <p>{service?.category_name}</p>
                                    </div>
                                    <div className="table-column-right">
                                      <p>
                                        {service?.price
                                          ? service?.price?.toFixed(2)
                                          : '-'}
                                      </p>{' '}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </TabContext>
                  </Box>
                </div>
              </div>
              <div className="catalog-content-right">
                <div className="image-wrapper">
                  <img
                    src={
                      catalogDetails?.splash_screen_image ||
                      (images as any).phoneImg
                    }
                    alt="phone"
                    className={
                      catalogDetails?.splash_screen_image ? 'splash' : ''
                    }
                  />
                </div>
              </div>
            </DialogContent>
          )}
        </>
      )}
    </Dialog>
  );
};
