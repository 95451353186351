import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import images from '../../assets/images';
import {
  formatDate,
  getColorCodeAndClassForGoals,
  pluralize,
} from '../util/util';

const GoalsCard = ({ goal, editClickHandler, archiveClickHandler }) => {
  const navigate = useNavigate();

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  //========================================================

  return (
    <div
      aria-label="goal-card"
      onClick={() => navigate(`/company/business-goals/${goal?.id}`)}
      className={`incread-by-box ${getColorCodeAndClassForGoals(goal?.status, goal?.archive)?.class}`}
    >
      <div className="title-head">
        <h4>
          <span data-testid="navigation-btn" className="cr-pointer">
            {goal?.name}
          </span>{' '}
          {goal?.private && <span className="private">Private</span>}{' '}
          {goal?.archive && <em className="icon be-archive"></em>}
        </h4>
        <Button
          className="three-dot-btn xs-btn"
          onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
          }}
        >
          <em className="icon be-vertical-dots"></em>
        </Button>
        <Menu
          id="basic-goal-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={(e) => {
            (e as any).stopPropagation();
            setAnchorEl(null);
          }}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          className={'three-dot-menu-paper'}
        >
          <MenuItem
            data-testid="edit-dialog"
            className="toggle-menu-item-row"
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(null);
              editClickHandler(goal);
            }}
          >
            <img src={images.editIcon} alt="" /> Edit
          </MenuItem>
          <MenuItem
            data-testid="archive-dialog"
            className="toggle-menu-item-row"
            aria-label="archive-btn"
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(null);
              archiveClickHandler(goal);
            }}
          >
            <em className="icon be-archive"></em>{' '}
            {goal?.archive ? 'Unarchive' : 'Archive'}
          </MenuItem>
        </Menu>
      </div>
      <div className="progress-wrap">
        <div className="text-content">{goal?.description}</div>
        <div className="progress-bar">
          <Box sx={{ flexGrow: 1 }}>
            <BorderLinearProgress
              variant="determinate"
              value={
                goal?.kpis_count
                  ? (goal?.kpis_accomplished / goal?.kpis_count) * 100
                  : 0
              }
            />
          </Box>

          <div className="progress-text">
            <strong>
              {goal?.kpis_accomplished}/{goal?.kpis_count}
            </strong>
            <span>
              {' '}
              {pluralize(
                goal?.kpis_accomplished,
                'KPI',
                's',
                false,
                false
              )}{' '}
              accomplished
            </span>
          </div>
        </div>
      </div>
      <div className="box-footer">
        <span className="date-box">
          Target {formatDate(goal?.due_date, false, true, 'DD.MM.YY')}
        </span>
        <span className="status">
          {/* { onTrack: '#69F0AE' },{ progressing: '#FFD740' },{ offTrack: '#FF8A80' },{ accomplished: '#00C853' } */}
          {goal?.status?.replace('_', ' ')}{' '}
          {goal?.status != 'accomplished' && (
            <em
              className="color-dot"
              style={{
                backgroundColor: getColorCodeAndClassForGoals(
                  goal?.status,
                  goal?.archive
                )?.color,
              }}
            ></em>
          )}
          {goal?.status == 'accomplished' && !goal?.archive && (
            <img src={images.checkSuccessRounded} alt="icon" />
          )}
          {goal?.status == 'accomplished' && goal?.archive && (
            <img src={images.checkAccomplishedRounded} alt="icon" />
          )}
        </span>
      </div>
    </div>
  );
};

export default GoalsCard;
