import { Menu, MenuItem } from '@mui/material';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import React, { startTransition, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EditBuildcardNameDialog from '../../components/dialog/EditBuildcardNameDialog';
import { CatalogShow } from '../../pages/company/catalog/CatalogShow';
import CreateTemplateDialog from '../../pages/company/catalog/CreateTemplateDialog';
import { fetchCatalogDetails } from '../services/common.service';
import { currentSpaceState } from '../store/slices/companySlice';
import ArchiveBuildcardDialog from '../../components/dialog/company-space-dialog/ArchiveBuildcardDialog';
import {
  companyState,
  permissionState,
  userPermissionState,
  userState,
} from '../store/slices/userSlice';
import {
  formatDate,
  getBcState,
  getCardLable,
  isAllowedTo,
  isBuildCardUnpaid,
  isUserCioOrSpaceMember,
  redirectTo,
} from '../util/util';
import CardEntityTags from './CardEntityTags';
import GoalsTags from './GoalsTags';
import OverflowTooltip from './OverflowTooltip';

function BuildcardComp({
  card,
  entity,
  idx,
  spaceGoals,
  searchSpaceGoalList,
  updateGoals,
  setShowSearchTags,
  deleteTag,
  tagList,
  addTags,
  tagChangeHandler,
  shouldNavigate = true,
  showThreeDots = false,
  updateBuildCardAttributes = (cardId, newAttributes) => null,
  spaceUid = null,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);
  const currentUser = useSelector(userState);
  const currentSpace = useSelector(currentSpaceState);
  const companySel = useSelector(companyState);

  const [openCatalog, setOpenCatalog] = useState(false);
  const [catalogDetails, setCatalogDetails] = useState(null);
  const [catalogDetailsLoading, setCatalogDetailsLoading] = useState(false);
  const [anchorEpdf, setAnchorEpdf] = useState<null | HTMLElement>(null);
  const [openRenameBc, setOpenRenameBc] = useState(false);
  const [openArchiveBc, setOpenArchiveBc] = useState(false);
  const open = Boolean(anchorEpdf);

  const handleClosepdf = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEpdf(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, idx: number) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEpdf(event.currentTarget as HTMLElement);
  };

  const handleClose = () => {
    setOpenRenameBc(false);
  };

  const openRenameBcDialog = (e) => {
    e?.stopPropagation();
    setOpenRenameBc(true);
    setAnchorEpdf(null);
  };

  const handleRenameBcClose = () => {
    setOpenRenameBc(false);
  };

  // Archive Buildcard
  const openArchiveDialog = (e) => {
    e?.stopPropagation();
    setOpenArchiveBc(true);
    handleClosepdf(e);
  };

  const handleArchiveClose = () => {
    setOpenArchiveBc(false);
  };

  const canModifyBuildCard = [
    entity,
    { id: card?.enterprise_id, type: 'BuildCard' },
  ].some((item) =>
    isAllowedTo('modify', 'buildcard', permissions, item, userPermissions)
  );

  const downloadBcPdf = (ev) => {
    ev.stopPropagation();
    window.open(card?.build_card_pdf_url, '_blank', 'noopener,noreferrer');
    setAnchorEpdf(null);
  };

  // Create catalogue states and functions
  const [createTemplate, setCreateTemplate] = useState(false);
  const closeCreateTemplateDialog = () => {
    setCreateTemplate(false);
  };

  // Show catalogue states and functions

  const handleCatalogOpenClick = async () => {
    setOpenCatalog(true);
    setCatalogDetailsLoading(true);
    const response = await fetchCatalogDetails(
      companySel?.id,
      card?.catalog_id,
      dispatch
    );
    if (response?.['catalog']) {
      setCatalogDetails(response?.['catalog']);
    } else {
      handleCloseCatalog();
    }
    setCatalogDetailsLoading(false);
  };

  const handleCloseCatalog = () => {
    setOpenCatalog(false);
  };

  const threeDotMenuBooleans = {
    rename: canModifyBuildCard,
    downloadPdf: isUserCioOrSpaceMember(currentUser, currentSpace),
    archive: ['draft', 'care'].includes(card?.state) && canModifyBuildCard,
  };

  return (
    <div
      className="card-template"
      style={shouldNavigate || card?.archive ? {} : { marginBottom: 0 }}
    >
      <div
        data-testid="build-card-body"
        className="card-body"
        onClick={() =>
          shouldNavigate
            ? startTransition(() => {
                redirectTo({ id: spaceUid }, card, navigate);
              })
            : null
        }
        style={{ cursor: 'pointer' }}
      >
        <div className="tag-container has-right-content">
          <Stack direction="row" spacing={1}>
            <Chip
              label={getCardLable(card?.state)}
              className={getBcState(card?.state)}
            />
          </Stack>
          <div className="d-flex align-items-center">
            {isAllowedTo(
              'read',
              'space_goal',
              permissions,
              entity,
              userPermissions
            ) && (
              <GoalsTags
                {...{ card, idx, spaceGoals, searchSpaceGoalList, updateGoals }}
              />
            )}
            {showThreeDots && (
              <>
                <div
                  className="xs-btn is-icon-btn three-dots-btn icon-btn-wrap"
                  key={idx}
                  data-testid="goal-tags-comp"
                  onClick={(e) => e?.stopPropagation()}
                >
                  {Object.values(threeDotMenuBooleans).some(Boolean) ? (
                    <em
                      onClick={(e) => {
                        {
                          (e as any)?.stopPropagation();
                          handleClick(e, idx);
                        }
                      }}
                      className="icon be-vertical-dots"
                      data-testid="vertical-dots"
                      style={{ fontSize: '2rem; display:flex' }}
                    ></em>
                  ) : (
                    <Tooltip
                      title={'Only Space Members can edit Buildcard'}
                      placement="top"
                    >
                      <em
                        className="icon be-vertical-dots"
                        data-testid="vertical-dots"
                        style={{ fontSize: '2rem; display:flex' }}
                      ></em>
                    </Tooltip>
                  )}
                </div>
                <Menu
                  className="three-dot-menu-paper"
                  id="basic-menu"
                  anchorEl={anchorEpdf}
                  open={open}
                  onClose={handleClosepdf}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  {threeDotMenuBooleans.rename && (
                    <MenuItem
                      disabled={card?.archive}
                      className="toggle-menu-item-row"
                      onClick={openRenameBcDialog}
                    >
                      <div className="d-flex align-items-center">
                        <em className="icon be-edit"></em>Rename
                      </div>
                    </MenuItem>
                  )}

                  {threeDotMenuBooleans.downloadPdf &&
                    (card?.build_card_pdf_url ? (
                      <MenuItem
                        className="toggle-menu-item-row"
                        onClick={downloadBcPdf}
                        disabled={card?.archive}
                      >
                        <em className="icon be-download"></em> Buildcard summary
                      </MenuItem>
                    ) : (
                      <Tooltip title={'PDF Summary not found'}>
                        <MenuItem
                          // disabled={true}
                          className="toggle-menu-item-row"
                        >
                          <em className="icon be-download"></em> Buildcard
                          summary
                        </MenuItem>
                      </Tooltip>
                    ))}
                  {isAllowedTo(
                    'create',
                    'catalog',
                    permissions,
                    { id: card?.space_id || card?.space?.id, type: 'Space' },
                    userPermissions
                  ) &&
                    !['draft', 'pending_approval'].includes(card?.state) &&
                    !card?.catalog_id && (
                      <MenuItem
                        className="toggle-menu-item-row"
                        aria-label="archive-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCreateTemplate(true);
                          setAnchorEpdf(null);
                        }}
                      >
                        <em className="icon be-add-circle"></em> Create template
                      </MenuItem>
                    )}
                  {card?.catalog_id &&
                    isAllowedTo('read', 'catalog', permissions) && (
                      <MenuItem
                        className="toggle-menu-item-row"
                        aria-label="archive-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCatalogOpenClick();
                          setAnchorEpdf(null);
                        }}
                      >
                        <em className="icon be-eye"></em> View template
                      </MenuItem>
                    )}
                  {threeDotMenuBooleans.archive && (
                    <MenuItem
                      className="toggle-menu-item-row"
                      aria-label="archive-btn"
                      onClick={openArchiveDialog}
                    >
                      <em className="icon be-archive"></em>{' '}
                      {card?.archive ? 'Unarchive' : 'Archive'}
                    </MenuItem>
                  )}
                </Menu>
              </>
            )}
          </div>
        </div>

        <div className="inner-container">
          <div className="left">
            <div className="card-title-container">
              <OverflowTooltip
                text={card?.alias_name ?? card?.name}
                tooltip={card?.alias_name ?? card?.name}
                placement={'top'}
                className={`card-name ${card?.archive ? 'be-archive' : ''}`}
              />
              <div className="date-container">
                {['draft', 'pending_approval'].includes(card?.state) ? (
                  card?.weeks || card?.duration ? (
                    <span>{card?.weeks || card?.duration} weeks</span>
                  ) : (
                    ''
                  )
                ) : (
                  <>
                    <span className="title">
                      {['completed', 'care'].includes(card?.state)
                        ? 'DELIVERED'
                        : 'DELIVERY'}
                      :{' '}
                    </span>
                    <span>
                      {card?.delivery_date
                        ? formatDate(card?.delivery_date)
                        : '-'}
                    </span>
                  </>
                )}
              </div>
              <div className="sub-heading">
                Lead: {card?.created_by ?? card?.creator?.name}
              </div>
            </div>
          </div>
          <div className="right">
            <div className="progress-circle">
              <Tooltip
                className="progress-tooltip-popover"
                placement="bottom"
                enterTouchDelay={0}
                title={
                  <div className="credits">
                    <span>{card?.completion ?? 0}%</span>
                  </div>
                }
              >
                <span>
                  {!['draft', 'pending_approval', 'ready_for_kickoff'].includes(
                    card?.state
                  ) && (
                    <div className="progress-with-bg">
                      <CircularProgress
                        size={40}
                        variant="determinate"
                        className="bg-progress"
                        value={100}
                      />
                      <CircularProgress
                        size={40}
                        variant="determinate"
                        value={card?.completion ?? 0}
                      />
                      <span className="progress-val">
                        {Math.ceil(card?.completion ?? 0)}%
                      </span>
                    </div>
                  )}
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
        {isAllowedTo(
          'read',
          'entity_tag',
          permissions,
          entity,
          userPermissions
        ) && (
          <div className="card-tags-wrap">
            <CardEntityTags
              {...{
                card,
                setShowSearchTags,
                deleteTag,
                tagList,
                addTags,
                tagChangeHandler,
              }}
            />
          </div>
        )}
      </div>
      <div className="card-footer">
        <div className="content-box">
          <span className="title">Features</span>
          <div className="count">{card?.number_of_features}</div>
        </div>
        <div className="content-box">
          <span className="title">Platforms</span>
          <div className="count">
            {card?.number_of_platforms ?? card?.platforms?.length}
          </div>
        </div>
        <div className="content-box">
          <OverflowTooltip
            tooltip={isBuildCardUnpaid(card) ? card?.currency?.name : 'Credits'}
            text={isBuildCardUnpaid(card) ? card?.currency?.name : 'Credits'}
            placement={'right'}
            className={'title'}
          />
          <div className="count">
            {card?.credits?.toString()?.length > 7 ? (
              <OverflowTooltip
                placement="top"
                style={{ width: '70px', cursor: 'pointer', display: 'block' }}
                text={card?.credits ?? '0'}
                tooltip={card?.credits ?? 0}
              />
            ) : (
              (card?.credits ?? '0')
            )}
          </div>
        </div>
      </div>
      {createTemplate && (
        <CreateTemplateDialog
          buildCard={card}
          createTemplate={createTemplate}
          closeCreateTemplateDialog={closeCreateTemplateDialog}
          updateBuildCardAttributes={updateBuildCardAttributes}
        />
      )}

      <CatalogShow
        {...{
          openCatalog,
          handleCloseCatalog,
          catalogDetails,
          catalogDetailsLoading,
        }}
      />

      <EditBuildcardNameDialog
        {...{
          card,
          openRenameBc,
          handleClose: handleRenameBcClose,
          updateBuildCardAttributes,
        }}
      />
      {/* Archive space modal start */}

      <ArchiveBuildcardDialog
        {...{
          card,
          openArchiveBc,
          handleArchiveClose,
          updateBuildCardAttributes,
        }}
      />

      {/* Archive space modal end */}

      <EditBuildcardNameDialog
        {...{ card, openRenameBc, handleClose, updateBuildCardAttributes }}
      />
    </div>
  );
}

export default BuildcardComp;
function CloseMemberProfileDialog(e: any) {
  throw new Error('Function not implemented.');
}
